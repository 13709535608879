import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
// import React, { useState, useEffect } from "react";
// import { useTranslation, initReactI18next } from "react-i18next";
import { Grid } from '@mui/material';
import Services from '../Services';
import ContentAndNotify from '../ContentAndNotify';
import Header from '../Header';
import Footer from '../Footer';
import ReactGA from 'react-ga';
import LanguageSelection from '../LanguageSelection';
import { ga_api_key } from '../../config/ga';

function Home({ selectedLang, setSelectedLang }) {
  const [ipAddress, setAddress] = useState();
  const { t } = useTranslation();

  React.useEffect(() => {
    ReactGA.initialize(ga_api_key);
    ReactGA.pageview(window.location.pathname + window.location.search);
    fetch('https://api.ipify.org/?format=json')
      .then((res) => res.json())
      .then((json) => {
        setAddress(json.ip);
      });
  }, []);

  const WrapperGrid = styled(Grid)(() => ({
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: '1fr max-content',
  }));
  return (
    <WrapperGrid item lg={12}>
      <LanguageSelection
        selectedLang={selectedLang}
        setSelectedLang={setSelectedLang}
      />
      <Grid item lg={12} className="home-container">
        <Grid item lg={12} className="wrapper-grid">
          <Grid item lg={12} className="right-text-section">
            <Grid className="common-container">
              <Grid container direction="column">
                <Header t={t} />
                <Grid item lg={12}>
                  <Grid container>
                    <ContentAndNotify t={t} ipAddress={ipAddress} />
                    <Services t={t} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer t={t} ipAddress={ipAddress} />
    </WrapperGrid>
  );
}

export default Home;
