import axios from 'axios';
export const env = {
  url: 'https://161.35.170.94:8443/bookurtreat-comingsoon/api/v1.0/',
};

export const ENDPOINT_URL = {
  LAUNCH_SIGNUP: `${env.url}bookurtreat-launch-notification-signup`,
  EXCLUSIVE_SIGNUP: `${env.url}bookurtreat-exclusive-invitation-signup`,
  EXCLUSIVE_VERIFY: `exclusive-invitation-email-verification`,
  LAUNCH_VERIFY: `launch-notification-email-verification`,
};

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error.response);
//   }
// );

export const PutRequest = (url, data) => {
  const URL = `${env.url}${url}`;
  return axios.put(URL, data, {
    headers: { 'Content-Type': 'application/json' },
  });
};
