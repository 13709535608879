import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  color: theme.colors.primary,
}));

export const WrapperDiv = styled('div')(() => ({
  position: 'absolute',
  zIndex: '999',
  top: '3rem',
  right: '8rem',
}));
