// import { makeStyles } from '@material-ui/core/styles';
// const styles = makeStyles((theme) => ({
//   image: {
//     width: '50px',
//     height: '50px',
//     [theme.breakpoints.down('sm')]: {
//       width: '30px',
//       height: '30px',
//     },
//   },
//   facilityHoler: {
//     [theme.breakpoints.only('sm')]: {
//       display: 'grid',
//       gridTemplateColumns: 'repeat(4, 20%)',
//     },
//   },
//   facilityHolerMain: {
//     [theme.breakpoints.only('sm')]: {
//       paddingLeft: '0 !important',
//     },
//   },
//   first: {
//     [theme.breakpoints.only('xs')]: {
//       borderTopLeftRadius: '4px !important',
//     },
//   },
//   fourth: {
//     [theme.breakpoints.only('xs')]: {
//       borderTopRightRadius: '4px !important',
//     },
//   },
//   fiveth: {
//     [theme.breakpoints.only('xs')]: {
//       borderBottomLeftRadius: '4px !important',
//     },
//   },
//   last: {
//     [theme.breakpoints.only('xs')]: {
//       borderBottomRightRadius: '4px !important',
//     },
//   },
// }));

// export default styles;
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const ContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only('sm')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 20%)',
  },
}));

export const WrapperGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.only('sm')]: {
    paddingLeft: '0 !important',
  },
}));

export const Img = styled('img')(({ theme }) => ({
  width: '50px',
  height: '50px',
  [theme.breakpoints.down('sm')]: {
    width: '30px',
    height: '30px',
  },
}));

const PREFIX = 'grid';

export const classes = {
  first: `${PREFIX}-first`,
  fourth: `${PREFIX}-fourth`,
  fifth: `${PREFIX}-fifth`,
  last: `${PREFIX}-last`,
};
export const GridContent = styled(Grid)(({ theme }) => ({
  [`&.${classes.first}`]: {
    [theme.breakpoints.only('xs')]: {
      borderTopLeftRadius: '4px !important',
    },
  },
  [`&.${classes.fourth}`]: {
    [theme.breakpoints.only('xs')]: {
      borderTopRightRadius: '4px !important',
    },
  },
  [`&.${classes.fifth}`]: {
    [theme.breakpoints.only('xs')]: {
      borderBottomLeftRadius: '4px !important',
    },
  },
  [`&.${classes.last}`]: {
    [theme.breakpoints.only('xs')]: {
      borderBottomRightRadius: '4px !important',
    },
  },
}));
