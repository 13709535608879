import React from 'react';

import { ContainerGrid, WrapperDiv } from './styles';
import { Grid, MenuItem } from '@mui/material';
import denmark from '../../assets/images/denmark.svg';
import finland from '../../assets/images/finland.svg';
import france from '../../assets/images/france.svg';
import germany from '../../assets/images/germany.svg';
import greece from '../../assets/images/greece.svg';
import iceland from '../../assets/images/iceland.svg';
import italy from '../../assets/images/italy.svg';
import luxembourg from '../../assets/images/luxembourg.svg';
import norway from '../../assets/images/norway.svg';
import netherlands from '../../assets/images/netherlands.svg';
import spain from '../../assets/images/spain.svg';
import sweden from '../../assets/images/sweden.svg';
import turkey from '../../assets/images/turkey.svg';
import uk from '../../assets/images/uk.svg';

import { MenuPopover } from '../Header/menuPopover';

import i18n from 'i18next';

const LanguageSelection = ({ selectedLang, setSelectedLang }) => {
  const flagList = [
    {
      img: uk,
      name: 'English(UK)',
    },

    {
      img: france,
      name: 'France',
    },
    {
      img: denmark,
      name: 'Denmark',
    },
    {
      img: finland,
      name: 'Finland',
    },
    {
      img: germany,
      name: 'Germany',
    },
    {
      img: greece,
      name: 'Greece',
    },
    {
      img: iceland,
      name: 'Iceland',
    },
    {
      img: italy,
      name: 'Italy',
    },
    {
      img: luxembourg,
      name: 'Luxembourg',
    },
    {
      img: norway,
      name: 'Norway',
    },
    {
      img: netherlands,
      name: 'Netherlands',
    },
    {
      img: spain,
      name: 'Spain',
    },
    {
      img: sweden,
      name: 'Sweden',
    },
    {
      img: turkey,
      name: 'Turkey',
    },
  ];

  const handleClose = (country) => {
    if (typeof country === 'string') {
      let lang = '';
      if (country.includes('English')) {
        lang = 'en';
      }
      if (country.includes('France')) {
        lang = 'fr';
      }
      if (country.includes('Spain')) {
        lang = 'es';
      }
      if (country.includes('Sweden')) {
        lang = 'se';
      }
      if (country.includes('Turkey')) {
        lang = 'tr';
      }
      if (country.includes('Netherlands')) {
        lang = 'nl';
      }
      if (country.includes('Norway')) {
        lang = 'no';
      }
      if (country.includes('Luxembourg')) {
        lang = 'lu';
      }
      if (country.includes('Italy')) {
        lang = 'it';
      }
      if (country.includes('Iceland')) {
        lang = 'is';
      }
      if (country.includes('Greece')) {
        lang = 'el';
      }
      if (country.includes('Germany')) {
        lang = 'de';
      }
      if (country.includes('Finland')) {
        lang = 'fi';
      }
      if (country.includes('Denmark')) {
        lang = 'dk';
      }

      setSelectedLang(flagList.find(({ name }) => name === country));

      if (lang) {
        i18n.changeLanguage(lang, (error, t) => {
          if (error) {
            console.log({ error });
          } else {
            setSelectedLang(flagList.find(({ name }) => name === country));
          }
        });
      }
    }
  };

  return (
    <WrapperDiv>
      <ContainerGrid item lg={12} xs={12} direction="row" container>
        <Grid item lg={2} md={3} xs={5} className="list-drop-down">
          <MenuPopover lang={selectedLang}>
            <div className="top-menu">
              <ul>
                {flagList.map(({ name, img }, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => handleClose(name)}
                      className="list-item"
                    >
                      <img width="25" src={img} alt="icon" />{' '}
                      <span> {name}</span>
                    </MenuItem>
                  );
                })}
              </ul>
            </div>
          </MenuPopover>
        </Grid>
      </ContainerGrid>
    </WrapperDiv>
  );
};

export default LanguageSelection;
