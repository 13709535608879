import React from 'react';
import { Grid } from '@mui/material';

const Header = ({ t }) => {
  return (
    <>
      <Grid item lg={10} md={9} xs={7} id="gridHeight">
        <div className="comming-soon"> {t('comingSoon')}</div>
      </Grid>
    </>
  );
};

export default Header;
