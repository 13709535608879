import { styled } from '@mui/material/styles';

export const LogDiv = styled('div')(({ theme }) => ({
  padding: '20px 0 0 0',
  color: theme.colors.secondary,
  '@media (min-width: 768px)': {
    padding: '30px 0 0 0',
  },
}));

export const SubTitle = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  padding: '20px 0px 0px',
  maxwidth: '80%',
  color: theme.colors.secondary,
  '@media (min-width: 768px)': {
    padding: '20px 0px 0px',
    fontSize: '36px',
  },
}));

export const Quotes = styled('div')(({ theme }) => ({
  fontsize: '14px',

  fontWeight: 700,
  lineHeight: 1.3,
  padding: '20px 0px',
  maxWidth: '60%',
  '@media (min-width: 768px)': {
    fontSize: '38px',
    padding: '20px 0px',
    maxWidth: '100%',
  },
  '@media (min-width: 668px) and (max-width: 899px)': {
    maxWidth: '60%',
  },
  color: theme.colors.primary,
}));
export const SignUp = styled('div')(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  padding: '20px 0px 30px 00px',
  maxWidth: '60%',
  ' @media (min-width: 768px)': {
    fontsize: '26px',
    maxWidth: '100%',
  },
  '@media (min-width: 668px) and (max-width: 899px)': {
    maxWidth: '60%',
  },
  color: theme.colors.secondary,
}));

export const Img = styled('img')(({ theme }) => ({
  maxHeight: '60px',
  [theme.breakpoints.only('sm')]: {
    maxHeight: '52px',
  },
  [theme.breakpoints.only('xs')]: {
    maxHeight: '30px',
  },
}));
