import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';
import { database } from '../../firebase/firebase.config';
import { get, ref, set } from 'firebase/database';
import butlogo from '../../assets/images/butlogo.png';

export const LanuchInvitationEmailVerify = () => {
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  let { search } = useLocation();
  const { id } = queryString.parse(search);

  useEffect(() => {
    const fetchData = async () => {
      const snapshot = await get(ref(database, `users/${id}`));

      if (snapshot.exists()) {
        const user = { ...snapshot.val(), emailVerified: true };
        set(ref(database, `users/${id}`), user);
        setData({
          success: true,
          message: 'Congratulations your email has been verified successfully',
        });
      } else {
        setErrors({
          success: false,
          error_message:
            'There was an error confirming your email please refresh the page or click the link in your email.',
        });
      }
    };
    fetchData();
  }, [id]);

  return (
    <Grid item lg={12} direction="row" container>
      <Container>
        <Grid item lg={12}>
          <div className="email-verify">
            <div className="logo">
              <img src={butlogo} alt="icon" />
            </div>

            {!!data && data.success && (
              <Alert severity="success">
                <AlertTitle className="title">Success</AlertTitle>
                <p>{data.message}</p>
              </Alert>
            )}
            {!!hasError && !hasError.success && (
              <Alert severity="error">
                <AlertTitle className="title">Error</AlertTitle>
                <p>{hasError.error_message}</p>
              </Alert>
            )}
          </div>
        </Grid>
      </Container>
    </Grid>
  );
};
