import emailjs from '@emailjs/browser';

export const sendEmail = async (parameters) => {
  try {
    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      parameters,
      process.env.REACT_APP_PUBLIC_KEY
    );
    
    return response;
  } catch (err) {
    console.log('FAILED...', err);
    return err;
  }
};
