import React from 'react';
import faceTreatment from '../../assets/images/face-treatment.svg';
import hairRemoval from '../../assets/images/hair-removal.svg';
import hairSalon from '../../assets/images/hair-salon.svg';
import makeup from '../../assets/images/makeup.svg';
import nails from '../../assets/images/nails.svg';
import spa from '../../assets/images/spa.svg';
import tattoos from '../../assets/images/tattoos.svg';
import tanning from '../../assets/images/tanning.svg';
import { Box } from '@mui/material';
import {
  ContainerGrid,
  WrapperGrid,
  Img,
  GridContent,
  classes,
} from './styles';

import { isMobile } from 'react-device-detect';

const facilityArray = [
  {
    img: faceTreatment,
    name: 'Face Treatment',
  },
  {
    img: hairRemoval,
    name: 'Hair Removal',
  },
  {
    img: hairSalon,
    name: 'Hair Salon',
  },
  {
    img: makeup,
    name: 'Makeup, brows',
  },
  {
    img: spa,
    name: 'Med Spa',
  },
  {
    img: nails,
    name: 'Nails',
  },
  {
    img: tattoos,
    name: 'Tattoos',
  },
  {
    img: tanning,
    name: 'Tanning',
  },
];

function Services() {
  return (
    <WrapperGrid container item lg={6} md={9} xs={12} alignItems="flex-end">
      <ContainerGrid
        className="inner-service"
        container
        direction="row"
        alignItems="flex-end"
      >
        {facilityArray.map((service, index) => (
          <GridContent
            className={`service-grid ${
              index === 0
                ? classes.first
                : index === 3
                ? classes.fourth
                : index === 4
                ? classes.fifth
                : index === 7
                ? classes.last
                : ''
            }`}
            key={service.name}
          >
            <Img src={service.img} alt="icon" />
            <Box pt={isMobile ? 0 : 1} className="service-text">
              <b>{service.name} </b>
            </Box>
          </GridContent>
        ))}
      </ContainerGrid>
    </WrapperGrid>
  );
}

export default Services;
