import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  colors: {
    primary: '#ffb63e',
    secondary: `#1d252d`,
    tertiary: '#e4e4e4',
  },
});

export default theme;
